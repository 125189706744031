import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
	Container,
	Title,
	Content,
	Button,
  Grid
} from '../components/styled'
import Quote from '../components/Quote'
import Contact from '../components/Contact'

export default ({ data }) => {
  //console.log(data)
  return (
  <Layout>
    <SEO
      title="UX/SEO-Audit - Webseite noch besser machen - Konkrete Maßnahmen"
      description="► Audit in den Bereichen User Experience (UX) und Suchmaschinenoptimierung (SEO): ✓ UX-Audit ✓ SEO-Audit ✓ Standort Köln / Bonn ► UX/SEO-Audit anfragen"
    />
  	<Container>
  		<Container.Inner>
  			<Content>UX/SEO-Audit</Content>
		    <Title as="h1">Konkrete Maßnahmen, mit denen Sie Ihre Webseite noch besser machen</Title>
		    <Content big>
		    	<p>Ein Audit in den Bereichen User Experience (UX) und Suchmaschinenoptimierung (SEO) liefert Ihnen konkrete Maßnahmen, mit denen Sie Schritt für Schritt Ihre Webseite oder Landingpage verbessern und mehr Umsatz im Web erzielen.</p>
          <Button as={Link} primary="true" to="/kontakt/">UX/SEO-Audit anfragen</Button>
		    </Content>
		  </Container.Inner>
	  </Container>
    <Container>
      <Container.Inner>
        <Quote {...data.allTestimonialsYaml.edges.find(el => el.node.name === 'anwaltverlag').node} />
      </Container.Inner>
    </Container>
	  <Container>
  		<Container.Inner>
        <Grid className="nb5">
          <Grid.Col className="mb5 w-50-l">
            <Title smaller>UX-Audit</Title>
            <Content>
              <p>Die User Experience entscheidet über Erfolg und Misserfolg. Fühlt sich der Nutzer „nicht wohl“, wird er die Webseite oder Landingpage verlassen, egal wie viel Geld Sie für Ads oder SEO ausgeben. Im UX-Audit fließen ebenfalls Best Practices aus der Conversion Rate Optimierung mit ein, damit neben den Bedürfnissen der Nutzer auch die des Unternehmens erfüllt werden.</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-50-l">
            <Title smaller>SEO-Audit</Title>
            <Content>
              <p>Ein Onpage-Audit ist ideal, um die technischen und inhaltlichen Faktoren in der Suchmaschinenoptimierung zu überprüfen. Aus den gewonnenen Daten werden sinnvolle Maßnahmen je nach Webseite, Zielsetzung und Budget abgeleitet und priorisiert. Der SEO-Audit ist die Basis für Ihren Erfolg in der Suchmaschine Google.</p>
            </Content>
          </Grid.Col>
        </Grid>
  		</Container.Inner>
  	</Container>
    <Contact>
      <Title center>Optimieren Sie jetzt Ihre Webseite, mit konkreten Empfehlungen</Title>
      <Content big center>
        <p>In einem UX- oder SEO-Audit, oder beidem, erfahren Sie, welche Maßnahmen Sie jetzt als nächstes angehen sollten.</p>
        <Button as={Link} primary="true" to="/kontakt/">UX/SEO-Audit anfragen</Button>
      </Content>
    </Contact>
  </Layout>
)}

export const query = graphql`
  query {
    allTestimonialsYaml(filter: {name: {in: [ "anwaltverlag"]}}) {
      edges {
        node {
          name
          children: content
          source
          image {
            ...rectangleImage
          }
          logo {
            ...logo
          }
        }
      }
    }
  }
`
